import { Router } from '@reach/router';
import React, { useEffect, useState, useContext } from 'react';
import { PrivateRoutes } from '@codeparva/gatsby-theme-tfw-2/src/components/account/handlers/PrivateRoutes';
import 'style/app.scss';
import {
  AccountRestrictedPage,
  AccountSelectionPage,
  ForgotPasswordPage,
  LoginPage,
  PasswordResetPage,
  CreateAccountPage,
} from '@codeparva/gatsby-theme-tfw-2/src/components/account/pages';
import { AccountPanelPageRoute } from '@codeparva/gatsby-theme-tfw-2/src/components/account/pages/AccountPanelRoutes';
import { FacilityContext } from '@codeparva/gatsby-theme-tfw-2/src/contexts/facilityContext';
import { LocationsContext } from '@codeparva/gatsby-theme-tfw-2/src/contexts/locationsContext';
import { LayoutContext } from '@codeparva/gatsby-theme-tfw-2/src/contexts/layoutContext';
import { initPage } from '@codeparva/gatsby-theme-tfw-2/src/utils/initializePage';
import { LoaderComponent } from '@codeparva/gatsby-theme-tfw-2/src/components/loader/Loader';
import {
  fetchStrapiFacilityDetails,
  fetchStrapiLocationDetails,
} from '@codeparva/v11-tfw-api-library';

// eslint-disable-next-line @typescript-eslint/no-var-requires
// tslint:disable-next-line:no-var-requires
const qs = require('qs');

const Account = (props: any) => {
  const { uri = '' } = props;
  const [hasMounted, setHasMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const facilityDetailsContext = useContext(FacilityContext);
  const locationDetailsContext = useContext(LocationsContext);
  const layoutDetailsContext = useContext(LayoutContext);

  const generatePopulateQuery = (listOfFields: any) => {
    if (listOfFields) {
      return qs.stringify(
        {
          populate: listOfFields,
        },
        {
          encodeValuesOnly: true,
        },
      );
    }
    return '';
  };

  const generateFilterPopulateQuery = (query: any) => {
    return query ? qs.stringify(query) : '';
  };

  async function callStrapiApi() {
    // const isContextCleared =
    //   Object.keys(facilityDetailsContext).length === 0 ||
    //   Object.keys(locationDetailsContext).length === 0 ||
    //   Object.keys(layoutDetailsContext).length === 0;

    // const isDbExists = await checkIfDbExists();

    const facilityDetailsQuery = generatePopulateQuery({
      facility_details: {
        populate: '*',
      },
      location_id: {
        populate: '*',
      },
      top_bar_config: {
        populate: '*',
      },
      nav_bar_config: {
        populate: {
          buttons: {
            populate: ['cta_icon_config', 'cta_button_theme'],
          },
        },
      },
      footer_config: {
        populate: '*',
      },
    });

    const [err, { data: { data: facilityData = {} } = {} } = {}] = await fetchStrapiFacilityDetails(
      facilityDetailsQuery,
      process.env.GATSBY_STRAPI_FACILITY_ID,
    );
    if (err) {
      console.error('Error while fetching facility details from strapi');
      throw err;
    }

    const location_ids_to_filter = facilityData.attributes.location_id.data.map(
      (location: any) => location.id,
    );
    if (location_ids_to_filter.length > 0) {
      const locationDetailsQuery = generateFilterPopulateQuery({
        filters: {
          id: {
            $in: location_ids_to_filter,
          },
        },
        populate: {
          location_contact_details: {
            populate: '*',
          },
          view_rates_page: {
            populate: '*',
          },
        },
      });

      const [error, { data: { data: locationData = {} } = {} } = {}] =
        await fetchStrapiLocationDetails(locationDetailsQuery);
      if (error) {
        console.error('Error while fetching location details from strapi');
        throw err;
      }

      const layoutData = {
        footerConfig: facilityData.attributes.footer_config,
        navbarConfig: facilityData.attributes.nav_bar_config,
        topbarConfig: facilityData.attributes.top_bar_config,
      };

      const inProps = {
        pageContext: {
          facilityDetails: facilityData.attributes.facility_details,
          allAvailableLocations: locationData,
          layoutConfigs: layoutData,
        },
        setFacilityDetails: props.setFacilityDetails,
        setAllLocationDetails: props.setAllLocationDetails,
        setLayoutDetails: props.setLayoutDetails,
      };
      // const dbStoreObj = {
      //   ...inProps.pageContext,
      // };
      await initPage(inProps, facilityDetailsContext, locationDetailsContext, layoutDetailsContext);
      // await addContextToDb(dbStoreObj);
    }
  }

  useEffect(() => {
    setHasMounted(true);
    try {
      callStrapiApi();
    } catch (e) {
      console.log('Error', e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  if (!hasMounted) {
    return null;
  }

  return (
    <>
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <>
          <Router basepath={uri}>
            <LoginPage path="login" />
            <CreateAccountPage path="create-account/:encryptedData" />
            <PrivateRoutes
              path=":Id/*"
              component={AccountPanelPageRoute}
              showSessionTimeOutTimer={true}
            />
            <ForgotPasswordPage path="forgot-password" setIsLoading={setIsLoading} />
            <PasswordResetPage path="password-reset" />
            <PrivateRoutes
              path="account-selection"
              component={AccountSelectionPage}
              showSessionTimeOutTimer={true}
            />
            <PrivateRoutes path="account-restricted" component={AccountRestrictedPage} />
          </Router>
        </>
      )}
    </>
  );
};

export default Account;
